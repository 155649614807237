<template>
	<div class="relative w-screen h-screen min-h-screen max-h-screen scrollbar-hide text-dark-100 touch-pan-y bg-black">

		<Head :title="seoData.title">
			<meta :content="seoData.description" name="description">
		</Head>

		<ToastList/>

		<div class="relative max-w-2xl mx-auto max-h-app h-app overflow-y-scroll scrollbar-hide">
			<slot/>
		</div>

		<InstallPWA/>
		<AppMenu/>
		<BackButtonAd/>
	</div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import AppMenu from "@/Shared/AppMenu.vue";
import ToastList from "@/Components/ToastNotifications/ToastList.vue";
import {Head, usePage} from "@inertiajs/vue3";
import InstallPWA from "@/Shared/InstallPWA.vue";
import BackButtonAd from "@/Shared/BackButtonAd.vue";
import {MasterOfPoppits} from "@/Modules/master-of-poppits";
import {useAnalyticsStore} from "@/Stores/AnalyticsStore";
import {useWpaInstallStore} from "@/Stores/WpaInstallStore.js";
import {storeToRefs} from "pinia";

const seoData = ref(usePage().props.seoData);
const analyticsStore = useAnalyticsStore();
const settings = ref(usePage().props.settings)
const wpaInstallStore = useWpaInstallStore();
const referrer = ref('default');
const pagesWithNoAds = computed(() =>
	['/login', '/register', '/app', '/user', '/reset-password', '/forgot-password', '/email']
);

onMounted(() => {
	setViewHeight();
	window.addEventListener('resize', setViewHeight);

	loadAds();
})

const loadAds = () => {

	// skip if the user on these pages
	if (pagesWithNoAds.value.some(path => window.location.pathname.startsWith(path))) {
		return;
	}

	// Get the URL parameters
	const urlParams = new URLSearchParams(window.location.search);

	// Check if 'ref' parameter is present and 'ref' is not already in session storage
	if (urlParams.has('ref') && !sessionStorage.getItem('ref')) {
		// Store 'ref' parameter in session storage
		sessionStorage.setItem('ref', urlParams.get('ref'));
		referrer.value = urlParams.get('ref');
	}

	window.masterOfPoppits = new MasterOfPoppits({
		initialDelay: settings.value.pop_initial_delay || 5,
		poppitInterval: settings.value.pop_interval || 60,
		poppitUrls: [
			{ url: 'https://revive.videobaba.xyz/revive/www/admin/plugins/redirectAd/redirect.php?zoneid=315', weight: 99 },
			{ url: 'https://www.bollyocean.com/api/direct/450270?s1=3sex', weight: 1 }
		],
		onSuccess: (url) => {
			analyticsStore.sendAnalyticsEvent('popunder', {
				ref: sessionStorage.getItem('ref') || 'default',
				url: url,
				ad_network: getAdNetwork(url),
			});
		},
	});

	// load clickadila script
	setTimeout(() => {
		const script = document.createElement('script');
		script.src = 'https://js.wpadmngr.com/static/adManager.js';
		script.setAttribute('data-admpid', '155467');
		script.async = true;
		document.body.appendChild(script);
	}, 6000);
}

const setViewHeight = () => {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const getAdNetwork = (url) => {
	if (url.includes('s.pemsrv.com')) {
		return 'EXO';
	}
	if (url.includes('tsyndicate.com')) {
		return 'Traffic Stars';
	}
	return 'Unknown';
}
</script>
